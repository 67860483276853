import {iconUrl} from 'data/icon'
import {Attribute} from 'event'
import {ensureActions} from '../type'

export const SGE = ensureActions({
	/** Single-Target Heals/Shields */
	DIAGNOSIS: {
		id: 24284,
		name: 'Diagnosis',
		icon: iconUrl(3652),
		onGcd: true,
		speedAttribute: Attribute.SPELL_SPEED,
		castTime: 1500,
		mpCost: 400,
	},
	EUKRASIAN_DIAGNOSIS: {
		id: 24291,
		name: 'Eukrasian Diagnosis',
		icon: iconUrl(3659),
		onGcd: true,
		gcdRecast: 1500,
		mpCost: 900,
		statusesApplied: ['EUKRASIAN_DIAGNOSIS', 'DIFFERENTIAL_DIAGNOSIS'],
	},
	HAIMA: {
		id: 24305,
		name: 'Haima',
		icon: iconUrl(3673),
		cooldown: 120000,
		statusesApplied: ['HAIMA', 'HAIMATINON'],
	},
	EGEIRO: {
		id: 24287,
		name: 'Egeiro',
		icon: iconUrl(3655),
		onGcd: true,
		speedAttribute: Attribute.SPELL_SPEED,
		castTime: 8000,
		mpCost: 2400,
	},

	/** AoE Heals/Shield */
	PROGNOSIS: {
		id: 24286,
		name: 'Prognosis',
		icon: iconUrl(3654),
		onGcd: true,
		speedAttribute: Attribute.SPELL_SPEED,
		castTime: 2000,
		mpCost: 800,
	},
	EUKRASIAN_PROGNOSIS: {
		id: 24292,
		name: 'Eukrasian Prognosis',
		icon: iconUrl(3660),
		onGcd: true,
		gcdRecast: 1500,
		mpCost: 900,
		statusesApplied: ['EUKRASIAN_PROGNOSIS'],
	},
	EUKRASIAN_PROGNOSIS_II: {
		id: 37034,
		name: 'Eukrasian Prognosis II',
		icon: iconUrl(3689),
		onGcd: true,
		gcdRecast: 1500,
		mpCost: 900,
		statusesApplied: ['EUKRASIAN_PROGNOSIS'],
	},
	PANHAIMA: {
		id: 24311,
		name: 'Panhaima',
		icon: iconUrl(3679),
		cooldown: 120000,
		statusesApplied: ['PANHAIMA', 'PANHAIMATINON'],
	},
	PHYSIS: {
		id: 24288,
		name: 'Physis',
		icon: iconUrl(3656),
		cooldown: 60000,
		statusesApplied: ['PHYSIS'],
	},
	PHYSIS_II: {
		id: 24302,
		name: 'Physis II',
		icon: iconUrl(3670),
		cooldown: 60000,
		statusesApplied: ['PHYSIS_II', 'AUTOPHYSIS'],
	},
	HOLOS: {
		id: 24310,
		name: 'Holos',
		icon: iconUrl(3678),
		cooldown: 120000,
		statusesApplied: ['HOLOS', 'HOLOSAKOS'],
	},
	PEPSIS: {
		id: 24301,
		name: 'Pepsis',
		icon: iconUrl(3669),
		cooldown: 15000,
	},

	/** Utility */
	KARDIA: {
		id: 24285,
		name: 'Kardia',
		icon: iconUrl(3653),
		cooldown: 5000,
		statusesApplied: ['KARDIA', 'KARDION'],
	},
	EUKRASIA: {
		id: 24290,
		name: 'Eukrasia',
		icon: iconUrl(3658),
		onGcd: true,
		gcdRecast: 1000,
		statusesApplied: ['EUKRASIA'],
	},
	SOTERIA: {
		id: 24294,
		name: 'Soteria',
		icon: iconUrl(3662),
		cooldown: 60000,
		statusesApplied: ['SOTERIA'],
	},
	ICARUS: {
		id: 24295,
		name: 'Icarus',
		icon: iconUrl(3663),
		cooldown: 45000,
	},
	ZOE: {
		id: 24300,
		name: 'Zoe',
		icon: iconUrl(3668),
		cooldown: 90000,
		statusesApplied: ['ZOE'],
	},
	KRASIS: {
		id: 24317,
		name: 'Krasis',
		icon: iconUrl(3685),
		cooldown: 60000,
		statusesApplied: ['KRASIS'],
	},
	PHILOSOPHIA: {
		id: 37035,
		name: 'Philosophia',
		icon: iconUrl(3690),
		cooldown: 180000,
		statusesApplied: ['EUDAIMONIA'],
	},
	// I guess Philosophia's Eudaimonia status effect produces Eudaimonia-action sourced heals?
	EUDAIMONIA: {
		id: 37036,
		name: 'Eudaimonia',
		icon: iconUrl(3690), // Technically it uses the generic cure icon but eh
	},

	/** Addersgall abilities */
	RHIZOMATA: {
		id: 24309,
		name: 'Rhizomata',
		icon: iconUrl(3677),
		cooldown: 90000,
	},
	DRUOCHOLE: {
		id: 24296,
		name: 'Druochole',
		icon: iconUrl(3664),
		cooldown: 1000,
	},
	IXOCHOLE: {
		id: 24299,
		name: 'Ixochole',
		icon: iconUrl(3667),
		cooldown: 30000,
	},
	KERACHOLE: {
		id: 24298,
		name: 'Kerachole',
		icon: iconUrl(3666),
		cooldown: 30000,
		statusesApplied: ['KERACHOLE', 'KERAKEIA'],
	},
	TAUROCHOLE: {
		id: 24303,
		name: 'Taurochole',
		icon: iconUrl(3671),
		cooldown: 45000,
		statusesApplied: ['TAUROCHOLE'],
	},

	/** Single-Target DPS Spells */
	DOSIS: {
		id: 24283,
		name: 'Dosis',
		icon: iconUrl(3651),
		onGcd: true,
		speedAttribute: Attribute.SPELL_SPEED,
		castTime: 1500,
		mpCost: 400,
	},
	DOSIS_II: {
		id: 24306,
		name: 'Dosis II',
		icon: iconUrl(3674),
		onGcd: true,
		speedAttribute: Attribute.SPELL_SPEED,
		castTime: 1500,
		mpCost: 400,
	},
	DOSIS_III: {
		id: 24312,
		name: 'Dosis III',
		icon: iconUrl(3680),
		onGcd: true,
		speedAttribute: Attribute.SPELL_SPEED,
		castTime: 1500,
		mpCost: 400,
	},
	EUKRASIAN_DOSIS: {
		id: 24293,
		name: 'Eukrasian Dosis',
		icon: iconUrl(3661),
		onGcd: true,
		gcdRecast: 1500,
		mpCost: 400,
		statusesApplied: ['EUKRASIAN_DOSIS'],
	},
	EUKRASIAN_DOSIS_II: {
		id: 24308,
		name: 'Eukrasian Dosis II',
		icon: iconUrl(3676),
		onGcd: true,
		gcdRecast: 1500,
		mpCost: 500,
		statusesApplied: ['EUKRASIAN_DOSIS_II'],
	},
	EUKRASIAN_DOSIS_III: {
		id: 24314,
		name: 'Eukrasian Dosis III',
		icon: iconUrl(3682),
		onGcd: true,
		gcdRecast: 1500,
		mpCost: 600,
		statusesApplied: ['EUKRASIAN_DOSIS_III'],
	},

	/** AoE DPS Spells */
	TOXIKON: {
		id: 24304,
		name: 'Toxikon',
		icon: iconUrl(3672),
		onGcd: true,
		speedAttribute: Attribute.SPELL_SPEED,
	},
	TOXIKON_II: {
		id: 24316,
		name: 'Toxikon II',
		icon: iconUrl(3684),
		onGcd: true,
		speedAttribute: Attribute.SPELL_SPEED,
	},
	DYSKRASIA: {
		id: 24297,
		name: 'Dyskrasia',
		icon: iconUrl(3665),
		onGcd: true,
		speedAttribute: Attribute.SPELL_SPEED,
		mpCost: 400,
	},
	DYSKRASIA_II: {
		id: 24315,
		name: 'Dyskrasia II',
		icon: iconUrl(3683),
		onGcd: true,
		speedAttribute: Attribute.SPELL_SPEED,
		mpCost: 400,
	},
	EUKRASIAN_DYSKRASIA: {
		id: 37032,
		name: 'Eukrasian Dyskrasia',
		icon: iconUrl(3687),
		onGcd: true,
		speedAttribute: Attribute.SPELL_SPEED,
		mpCost: 400,
		statusesApplied: ['EUKRASIAN_DYSKRASIA'],
	},
	PHLEGMA: {
		id: 24289,
		name: 'Phlegma',
		icon: iconUrl(3657),
		onGcd: true,
		gcdRecast: 2500,
		speedAttribute: Attribute.SPELL_SPEED,
		cooldown: 40000,
		charges: 2,
		mpCost: 400,
	},
	PHLEGMA_II: {
		id: 24307,
		name: 'Phlegma II',
		icon: iconUrl(3675),
		onGcd: true,
		gcdRecast: 2500,
		speedAttribute: Attribute.SPELL_SPEED,
		cooldown: 40000,
		charges: 2,
		mpCost: 400,
	},
	PHLEGMA_III: {
		id: 24313,
		name: 'Phlegma III',
		icon: iconUrl(3681),
		onGcd: true,
		gcdRecast: 2500,
		speedAttribute: Attribute.SPELL_SPEED,
		cooldown: 40000,
		charges: 2,
		mpCost: 400,
	},
	PSYCHE: {
		id: 37033,
		name: 'Psyche',
		icon: iconUrl(3688),
		onGcd: false,
		cooldown: 60000,
	},
	PNEUMA: {
		id: 24318,
		name: 'Pneuma',
		icon: iconUrl(3686),
		onGcd: true,
		speedAttribute: Attribute.SPELL_SPEED,
		castTime: 1500,
		gcdRecast: 2500,
		cooldown: 120000,
		mpCost: 700,
		statusesApplied: ['PNEUMA'],
	},
	// The heal from Pneuma is technically a different action source because reasons
	PNEUMA_HEAL: {
		id: 27524,
		name: 'Pneuma',
		icon: iconUrl(3686),
	},
})
